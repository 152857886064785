html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: calibri;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#loginDiv {
  margin: auto;
  width: 402px;
  height: 420px;
  margin-top: 7%;
  border: 1px solid lightgray;
  padding: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  padding-top: 3px;
}

#userName,
#password {
  font-size: 14px;
}

#userName:placeholder-shown {
  font-style: italic;

}

#password:placeholder-shown {
  font-style: italic;
}

body {
  font-family: calibri !important;
  background-color: #f3f3f4 !important;
  font-size: 14px;
}

div {
  font-size: 14px;

}

a.blueLink {
  color: #007bff !important;
}

a.blueLink:hover {
  text-decoration: none;
}

#passwordHelp {
  margin-left: 134px;
  font-size: 13px;

}

#login {
  width: 100%;
}

.a-divider.a-divider-break:after {
  content: "";
  width: 100%;
  background-color: transparent;
  display: block;
  height: 1px;
  border-top: 1px solid #e7e7e7;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  z-index: 1;
}

.a-divider.a-divider-break h5 {
  line-height: 1;
  font-size: 13px;
  color: #767676;
  font-weight: 400;
  z-index: 2;
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 0 8px 0 7px;
}

.a-divider.a-divider-break {
  text-align: center;
  position: relative;
  top: 5px;
  padding-top: 1px;
  margin-bottom: 9px;
  margin-top: 9px;
  line-height: 0;
}

#createAccount {
  width: 100%;
}

#loginWithMS {
  width: 100%;
  background-color: rgb(239, 239, 239);
  border-color: rgb(239, 239, 239) !important;
  font-weight: bold;
}

#loginWithMS:hover {
  background-color: #e4e2e2;
  color: black;
}

#loginWithMS:focus {
  outline: none;
  box-shadow: none;
}

#loginWithMS img {
  margin-right: 12px;
}

.fsTails div {
  height: 90px;
  margin-right: 46px;
  width: 152px;
  text-align: center;
}

.fsTails {
  padding: 10px;
}

.fsTail a {
  vertical-align: middle;
  padding-top: 29px;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  border: 1px solid lightblue;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.fsTail {
  cursor: pointer;
}

.footer {
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #efefef;
  text-align: center;
  z-index: 9999;
  margin: 0px 10px;
}

.footer p {
  font-size: 10px;
}

table#userDetailTable tr,
table#subscriptiondetailtable tr {
  height: 30px;
}

table#userDetailTable tr td:nth-child(1),
table#subscriptiondetailtable tr td:nth-child(1) {
  width: 140px;
}

table#userDetailTable tr td:nth-child(2),
table#subscriptiondetailtable tr td:nth-child(2) {
  font-weight: bold;
}

.sq-panel {
  border-top: 3px solid #94c8ff !important;
  width: 65%;
  margin-left: 20%;
  margin-top: 1%;
  background-color: white;
  height: 100%;
  box-shadow: rgb(204, 204, 204) 0px 0px 10px 0px;
}

#panel-title {
  border-bottom: 1px solid #ccc;
  height: 30px;
  padding-top: 3px;
  padding-left: 3px;
  color: #367eca !important;
  font-weight: bold;
}

table#planDetailsTable tr td {
  padding-bottom: 13px;
}

table#planDetailsTable tr td:nth-child(1) {
  padding-bottom: 13px;
  width: 200px;
}

label#billingFrequency {
  margin-bottom: 0px;
  font-weight: bold;
}


/*select {
  background: url(images/down-arrow-small.png) right 10px center no-repeat, -webkit-linear-gradient(#FFF 20%, #E4E4E4 80%) !important;
  -webkit-appearance: none;
  padding: 3px 8px !important;
  font-size: 14px !important;
  font-family: calibri !important;
  border: 1px solid #c5c5c5;
}*/
#planSelector {
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 26px;
}

select:focus {
  outline-color: lightblue;
}

table {
  font-family: calibri;
  font-size: 14px;
}

a.blueLink {
  cursor: pointer;
  text-decoration: none;
}

.popup-inner {
  max-width: 400px;
  width: 90%;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px rgb(0 0 0);
  -webkit-box-shadow: 0px 2px 6px rgb(0 0 0);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 1);
  -ms-box-shadow: 0px 2px 6px rgba(0, 0, 0, 1);
  -o-box-shadow: 0px 2px 6px rgba(0, 0, 0, 1);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background: #fff;
  color: #666666;
  display: inline-block;
  text-align: center;
  border-top: 6px solid #4090e2;
  min-height: 100px;
}

h4#deleteAlertMsg,
h4#warningMsg,
h4#cancelAlertMsg,
h4#transactionMsg,
h4#infoMsg,
h4#successMsg {
  text-align: left;
  font-size: 14px;
  font-family: calibri;
  color: #414141;
  font-weight: 600;
}

.popup {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.sq-primary-btn {
  background: #4090e2;
  border: 1px solid #4090e2 !important;
  text-decoration: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -khtml-border-radius: 3px;
  padding: 3px 20px !important;
  color: #ffffff !important;
  cursor: pointer;
  float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  font-family: calibri;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.sq-cancel-btn {
  color: #414141 !important;
  background: #e4dfdf;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -khtml-border-radius: 3px;
  padding: 3px 20px;
  font-family: calibri;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #e4dfdf;
  cursor: pointer;
}

.fr {
  float: right;
}

.sq-primary-btn:hover {
  background: none;
  color: #4090e2 !important;
  border: 1px solid #4090e2;
  text-decoration: none;
  padding: 3px 20px;
}

table#planDetailsTable tr td:nth-child(1) {
  padding-bottom: 13px;
  width: 200px;
  height: 45px;
}

#planDetailsDiv,
#purchaseSummaryDiv {
  padding-left: 10px;
  padding-top: 9px;

}

#planDetailsDiv {
  width: 70%;
}

#purchaseSummaryDiv {
  width: 518px;
}

.separator-row {
  height: 75px;
  border-bottom: 1px solid lightgray;
}

.sq-cancel-btn {
  color: #414141 !important;
  background: #e4dfdf;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -khtml-border-radius: 3px;
  padding: 3px 20px;
  font-family: calibri;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #e4dfdf;
}

.border-black {
  color: #000 !important;
  border: 1px solid !important;
  background: none;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
}

input#coupon {
  height: 25px;
  font-family: calibri;
  font-size: 13px;
  font-weight: normal;
  width: 65px;
}

#discount-code-label {
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 3px;
}

#remove-coupon {
  font-family: calibri;
  font-size: 14px;
  height: 25px;
  padding-top: 1px;
}

#billing-form .form-group {
  margin-bottom: 4px;
}

.billing-section-label {
  background-color: lightgray;
  width: 200px;
}

.billing-scroller.scrolling-container {
  height: 70vh;
  overflow: auto;
}

label.required-label::after {
  content: '*';
  color: red;
}

.card-expiry-seperator {
  display: block;
  margin: 0px 15px;
  font-size: 20px;
  color: #ada5a5;
}

#cardDetailsDiv {
  height: 26vh;
  border-top: 1px solid lightgray
}

div#cardDetailsDiv .form-group {
  margin-bottom: 7px;
}

a#cancel-sub-link:hover {
  text-decoration: none;
}

#invoices thead tr th,
#users thead tr th {
  background-color: #ccc;
}

#confirmations thead tr th {
  background-color: #ccc;
}

table#invite-list-table thead tr th {
  background-color: lightgray;
}

.plan-detail-row {
  height: 40px;
}

.quantity-field {
  width: 45px;
  margin-right: 7px;
}

table#users thead tr th {
  background-color: #ccc;
}

table#users tbody tr td:last-child {
  border-right: 1px solid lightgray;
}

table#users tbody tr td:nth-child(1) {
  border-left: 1px solid lightgray;
}

table#users tbody tr td,
table#users thead tr th {
  height: 28px;
  padding-left: 6px;
  padding-right: 6px;
  border-bottom: 1px solid lightgray;
}

table#users thead tr th:last-child {
  border: 1px solid #ccc;
}

#change-owner-form label {
  font-weight: bold;
}

div.field-note {
  margin-bottom: 4px;
  line-height: 14px;
}

label.field-note,
div.field-note {
  font-style: italic !important;
  color: #747474;
  font-size: 14px !important;
  font-family: calibri;
  line-height: 16px;
}

table#invite-list-table thead tr th,
table#invite-list-table thead tr td {
  height: 25px;
}

.form-group label {
  font-weight: bold;
}

#fs-app-bar {
  background-color: #94c8ff !important
}

#fs-app-bar a.navbar-brand {
  color: black;
}

.userQuantity {
  border: 1px solid black;
  border-radius: 3px;
}

.seqnc-modal div.modal-body {
  font-weight: bold;
}

.seqnc-modal div.modal-footer {
  padding-top: 1px;
  padding-bottom: 1px;
}

.seqnc-modal div.modal-dialog {
  top: 20%;
}

.seqnc-modal div.modal-content {
  border-top: 5px solid #94c8ff !important;
}

.hidden {
  display: none;
}

select#card-expiry-month {
  width: 56px;
}

select#card-expiry-year {
  width: 70px !important;
}

.remove-user-chek:focus {
  outline-color: none;
}

.remove-user-chek:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-pop-info {
  text-align: center;
  min-width: 157px;
  text-transform: lowercase;
}

#user-pop .popover-body {
  padding-top: 0px;
}

form#signupForm label {
  font-weight: bold;
  margin-bottom: 2px;
}

.border-red {
  border: 1px solid red !important;
}

#cardDetailsDiv {
  width: 100%;
}

div#manage-users-tab-tabpane-updateBillings>div.scrolling-container {
  height: 65vh;
  overflow: auto;
}

div.rdt_TableHeadRow {
  font-weight: bold;
  background-color: lightgray;
}

div.rdt_TableHeader {
  display: none;
}

#invite-list-table {
  width: 47%;
}

#invite-list-table tr {
  height: 60px;
}

#content-area {
  width: 98%;
}

#navgationBtnsDiv {
  width: 519px;
}

#billingNavDiv{
  width:566px;
}

#updateBillingControls {
  width: 419px;
}

@media (max-width: 575.98px) {

  #loginDiv {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    border-radius: 0%;
  }

  #passwordHelp {
    margin-left: 15px;
  }

  .fsTails div {
    margin-right: 6px;
  }

  #billingFrequencySpan,
  #frequencyDdlSpan,
  #status-row {
    margin-bottom: 36px;
  }

  #addon-quantity-parent {
    padding-top: 2px;
  }

  #quantity-availability {
    padding-top: 6px;
  }

  #quantity-availability {
    margin-bottom: 16px;
  }

  .billing-scroller.scrolling-container {
    height: 66vh;
    overflow: auto;
  }

}

@media (max-width: 991.98px) {

  #billingNavDiv,
  #navgationBtnsDiv {
    width: 100%;
  }

  #billingNavDiv {
    border-top: 1px solid lightgray;
  }

  div#manage-users-tab-tabpane-updateBillings>div.scrolling-container {
    height: 60vh;
  }

  #updateBillingControls {
    width: 100%;
    border-top: 1px solid lightgray;
  }



  .sq-panel {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  #content-area {
    width: 100%;
  }

  .panel-content {
    width: 100%;
  }

  #planDetailsDiv,
  #purchaseSummaryDiv {
    width: 100%
  }
}

@media (max-width: 322px) {
  .fsTails div {
    margin-right: 3px;
  }
}